import React from 'react';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import cn from 'classnames';
import {
    Box,
    Button,
    AccordionMenu,
    WidgetTransaction,
    ModalSupport,
    CloseButton,
    ModalHowToStart,
    Image,
} from 'components/atomsKit/Elements';

import { userIcon } from 'img/userLogo';

import { soundTogle } from 'redux/interface/slice';
import { fetchSupportPhone } from 'redux/interface/operations';

import { formatDateTime, cutTextforToltip, renderLastMessage } from 'utils/common';
import { transactionContentConfig } from 'data/config';

import classes from './Transactions.module.scss';
import { notificationsStatuses } from 'components/atomsKit/Transactions/checkDocsStatuses';
import { useSelector } from 'react-redux';
import { selectDocs } from '../../../redux/auth/selectors';
import VerificationNotify from 'components/atomsKit/VerificationNotify/VerificationNotify';

interface IProps {
    haveNewNotification?: boolean;
    haveNewMemberActivity?: boolean;
    transactions?: any;
    notifications?: any;
    handleDelTransaction: (id: string) => void;
    handleDelNotification: (id: string) => void;
    handleTransaction: (
        chat_uid: string | null,
        id: number,
        isMail?: boolean,
        idTransaction?: string,
    ) => void;
    handleCloseAlert: () => void;
    userInfo?: any;
}

const Transactions = ({
    haveNewNotification,
    haveNewMemberActivity,
    transactions,
    notifications,
    handleDelTransaction,
    handleDelNotification,
    handleTransaction,
    handleCloseAlert,
    userInfo,
}: IProps) => {
    const docsState = useSelector(selectDocs);
    const [verificationStatus, setVerificationStatus] = useState({
        banner: '',
        title: '',
        text: '',
        onClick: false,
    });
    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const [isOpenMemberActivity, setIsOpenMemberActivity] = useState(false);
    const [isOpenMenuHelp, setIsOpenMenuHelp] = useState(false);
    const [isOpenModalSupport, setIsOpenModalSupport] = useState(false);
    const [isOpenModalTutorial, setIsOpenModalTutorial] = useState(false);

    const supportPhone = useAppSelector((state) => state.interface.supportPhone);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!supportPhone) {
            dispatch(fetchSupportPhone());
        }
    }, []);

    useEffect(() => {
        setVerificationStatus(
            notificationsStatuses.find(
                (notification) => notification?.banner === docsState?.banner,
            ) || { banner: '', title: '', text: '', onClick: false },
        );
    }, [docsState?.banner]);

    return (
        <>
            {isOpenModalTutorial && (
                <ModalHowToStart
                    modalIsOpen={isOpenModalTutorial}
                    closeModal={() => setIsOpenModalTutorial(false)}
                />
            )}

            {isOpenModalSupport && (
                <ModalSupport
                    modalIsOpen={isOpenModalSupport}
                    closeModal={() => {
                        setIsOpenModalSupport(false);
                    }}
                    payment_total={userInfo?.payment_total}
                    startMail={userInfo?.email}
                    placeholderInputMessage={'Your message to support'}
                />
            )}

            <div className={classes.wrap}>
                <Box className={classes.notifWrap}>
                    <AccordionMenu
                        text="NOTIFICATIONS"
                        handleOption={() => {
                            setIsOpenNotification(!isOpenNotification);
                        }}
                        isOpenOption={
                            isOpenNotification &&
                            (notifications?.length > 0 || !!verificationStatus?.banner)
                        }
                        marker={haveNewNotification || !!verificationStatus?.banner}
                    >
                        {docsState?.banner && (
                            <VerificationNotify
                                item={verificationStatus}
                                configID={docsState?.configID}
                            />
                        )}
                        {notifications
                            .slice()
                            .reverse()
                            .map((item: any, index: number) =>
                                item?.action === 'mail' ? (
                                    <WidgetTransaction
                                        key={index}
                                        id={item?.notification_object?.mail_id}
                                        message={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.text
                                        }
                                        name={item?.notification_object?.name}
                                        age={item?.notification_object?.age}
                                        imgLink={item?.notification_object?.avatar}
                                        date_created={formatDateTime(
                                            item?.notification_object?.date_created,
                                        )}
                                        imgLinkUser={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.isDoubleShow
                                                ? userInfo?.photo_link || userIcon
                                                : undefined
                                        }
                                        timeOff={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.timeOff
                                        }
                                        iconType={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.icon
                                        }
                                        bgColor={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.bgColor
                                        }
                                        handleDel={() => {
                                            handleDelNotification(item?.idTransaction);
                                        }}
                                        handleCard={() => {
                                            handleCloseAlert();
                                            handleTransaction(
                                                item?.notification_object?.chat_uid,
                                                item?.notification_object?.public_external_id,
                                                true,
                                                item?.idTransaction,
                                            );
                                        }}
                                    />
                                ) : (
                                    <WidgetTransaction
                                        key={index}
                                        id={item?.notification_object.id}
                                        message={renderLastMessage(
                                            item?.notification_object?.message_type,
                                            cutTextforToltip(
                                                item?.notification_object?.message_content,
                                                30,
                                            ),
                                        )}
                                        name={item?.notification_object?.name}
                                        age={item?.notification_object?.age}
                                        imgLink={item?.notification_object?.photo_link}
                                        date_created={formatDateTime(
                                            item?.message_object?.date_created,
                                        )}
                                        timeOff={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.timeOff
                                        }
                                        iconType={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.icon
                                        }
                                        bgColor={
                                            transactionContentConfig(
                                                item?.action,
                                                item?.notification_object?.name,
                                            )?.bgColor
                                        }
                                        handleDel={() => {
                                            handleDelNotification(item?.idTransaction);
                                        }}
                                        handleCard={() => {
                                            handleCloseAlert();
                                            handleTransaction(
                                                item?.notification_object?.chat_uid,
                                                item?.notification_object?.sender_external_id,
                                                false,
                                                item?.idTransaction,
                                            );
                                        }}
                                    />
                                ),
                            )}
                    </AccordionMenu>
                    <AccordionMenu
                        text="MEMBER ACTIVITY"
                        handleOption={() => {
                            setIsOpenMemberActivity(!isOpenMemberActivity);
                        }}
                        isOpenOption={isOpenMemberActivity && transactions.length > 0}
                        marker={haveNewMemberActivity}
                    >
                        {transactions
                            .slice()
                            .reverse()
                            .map((item: any, index: number) => (
                                <WidgetTransaction
                                    key={index}
                                    id={item?.id}
                                    message={
                                        transactionContentConfig(
                                            item?.action,
                                            item?.notification_object?.name,
                                        )?.text
                                    }
                                    name={item?.notification_object?.name}
                                    age={item?.notification_object?.age}
                                    imgLink={item?.notification_object?.photo_link}
                                    date_created={formatDateTime(
                                        item?.notification_object?.date_created,
                                    )}
                                    imgLinkUser={
                                        transactionContentConfig(
                                            item?.action,
                                            item?.notification_object?.name,
                                        )?.isDoubleShow
                                            ? userInfo?.photo_link || userIcon
                                            : undefined
                                    }
                                    timeOff={
                                        transactionContentConfig(
                                            item?.action,
                                            item?.notification_object?.name,
                                        )?.timeOff
                                    }
                                    iconType={
                                        transactionContentConfig(
                                            item?.action,
                                            item?.notification_object?.name,
                                        )?.icon
                                    }
                                    bgColor={
                                        transactionContentConfig(
                                            item?.action,
                                            item?.notification_object?.name,
                                        )?.bgColor
                                    }
                                    handleDel={() => {
                                        handleDelTransaction(item?.idTransaction);
                                    }}
                                    handleCard={() => {
                                        handleCloseAlert();
                                        handleTransaction(
                                            null,
                                            item?.notification_object?.sender_external_id,
                                            false,
                                            item?.idTransaction,
                                        );
                                    }}
                                />
                            ))}
                    </AccordionMenu>
                </Box>
                <Box padding="10px" className={classes.wrapHelpBaner}>
                    {isOpenMenuHelp ? (
                        <Box>
                            <Box flex align="center" justify="center" marginBottom="8px">
                                <Image name={'c4-support'} bgImg classNameIcon="btnIco" />
                                <p className={classes.title}>Support</p>
                            </Box>
                            {!!userInfo?.convert_user && (
                                <Box marginBottom="8px">
                                    <p className={classes.helpDescriprion}>
                                        Customer Support numbers:
                                    </p>
                                    <p className={classes.helpDescriprion}>
                                        US{' '}
                                        <a
                                            href={`tel:${supportPhone.split(' ').join('')}`}
                                            className={classes.tell}
                                        >
                                            {supportPhone}
                                        </a>
                                    </p>
                                </Box>
                            )}

                            <p className={classes.helpDescriprion}>
                                Choose a communication method that is convenient for you
                            </p>
                            <Box flex directionColumn align="center" justify="center">
                                {!!userInfo?.convert_user && (
                                    <Button
                                        onClick={() => window.zE.activate()}
                                        text="Live chat"
                                        iconSvg="c4_bottom_live_chat"
                                        isWhite
                                        noBg
                                        // color="#720066"
                                        className={classes.btnSupport}
                                        margin="0 0 8px 0"
                                        bgImg
                                        classNameIcon="btnIco"
                                    />
                                )}

                                <Button
                                    onClick={() => setIsOpenModalSupport(true)}
                                    text="Email"
                                    iconSvg="c4_bottom_email"
                                    isWhite
                                    noBg
                                    // color="#720066"
                                    className={classes.btnSupport}
                                    margin="0 0 8px 0"
                                    bgImg
                                    classNameIcon="btnIco"
                                />
                                {!!userInfo?.convert_user && (
                                    <Button
                                        onClick={() => window.zE.activate()}
                                        text="Request call"
                                        iconSvg="c4_bottom_call"
                                        isWhite
                                        noBg
                                        // color="#720066"
                                        className={classes.btnSupport}
                                        bgImg
                                        classNameIcon="btnIco"
                                    />
                                )}
                            </Box>
                            <Button
                                // color="#720066"
                                onClick={() => {
                                    setIsOpenModalTutorial(true);
                                }}
                                text={'How to Start'}
                                iconSvg={'how_to_start_ico'}
                                noBg
                                isWhite
                                className={cn(classes.btnSupport, classes.btnHowToStart)}
                                bgImg
                                classNameIcon="btnIco"
                            />
                            <CloseButton
                                onClick={() => {
                                    setIsOpenMenuHelp(false);
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    setIsOpenMenuHelp(true);
                                }}
                                text={'Help'}
                                iconSvg={'c4-support-primary-blue'}
                                noBg
                                isWhite
                                margin="0 0 10px 0"
                                bgImg
                                classNameIcon="btnIco"
                            />
                            <Button
                                text={
                                    localStorage.getItem('sound') === '1'
                                        ? 'Mute notifications'
                                        : 'Unmute notifications'
                                }
                                iconSvg={localStorage.getItem('sound') === '1' ? 'mute' : 'muted'}
                                noBg
                                isWhite
                                onClick={() => dispatch(soundTogle())}
                                bgImg
                                classNameIcon="btnIco"
                            />
                        </>
                    )}
                </Box>
            </div>
        </>
    );
};

export default Transactions;
