import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'hooks';

import { selectMyProfile, selectUser } from 'redux/auth/selectors';

import { togleGlobalModal, loaderOn, loaderOff } from 'redux/interface/slice';

import { getUserInfo, getMyProfile, logOut } from 'redux/auth/operations';

import { parserFilterOptions, ageOptions } from 'utils/common';

import { useDebouncedState } from 'hooks';

import { postRequest, getRequest } from 'utils/requests';

import {
    defaultFiltersMyselfFunc,
    defaultFiltersGirl,
    mmOptions,
    ddOptions,
    yyyyOptions,
    configForEditProfile,
} from 'data/config';

import uploadFile from 'utils/uploadFile';
import * as AWS from 'data/aws';
import { PAYMENT_TOTAL_TO_SHOW_CONTACTS } from '../../data/user';

const useProfilePage = () => {
    const user_detail = useSelector(selectMyProfile)?.user_detail;
    const user_reference = useSelector(selectMyProfile)?.user_reference;
    const user_hobby = useSelector(selectMyProfile)?.user_hobby;
    const user_match = useSelector(selectMyProfile)?.user_match;
    const avatar = useSelector(selectMyProfile)?.user_detail?.photo_link;
    const user_content = useSelector(selectMyProfile)?.user_content;
    const filtersOptions = useSelector(selectMyProfile)?.referencies_list;
    const userInfo = useSelector(selectUser);

    const [valuesFilterMySelf, setValuesFilterMySelf] = useState(
        defaultFiltersMyselfFunc(user_detail, user_reference, user_hobby),
    );

    const [visibleSaveBtn, setVisibleSaveBtn] = useState(false);

    const [isOpenModalMailConfirm, setIsOpenModalMailConfirm] = useState(false);
    const [isOpenModalMailChange, setIsOpenModalMailChange] = useState(false);
    const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false);
    const [isOpenModalModalConfirmName, setIsOpenModalModalConfirmName] = useState(false);
    const [isOpenModalPhoneConfirm, setIsOpenModalPhoneConfirm] = useState(false);

    const [cityOptions, setCityOptions] = useState([]);
    const [citySerch, debouncedCitySerch, setCitySerch] = useDebouncedState('');

    const [valuesFilterGirl, setValuesFilterGirl] = useState(
        defaultFiltersGirl(user_match, user_reference),
    );

    const [similarList, setSimilarList] = useState([]);

    const { isLoggedIn } = useAuth();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isShowGetVipBlock =
        !!userInfo?.userRoles?.heightRole ||
        !!userInfo?.userRoles?.maybeHeight ||
        +(userInfo?.user_info?.payment_total || 0) >= PAYMENT_TOTAL_TO_SHOW_CONTACTS;

    useEffect(() => {
        const isMan = !+userInfo?.user_info?.gender;
        const url = isMan ? '/api/v3/woman/similar' : '/api/v3/man/similar';
        getRequest(url).then((res) => {
            setSimilarList(res?.result);
        });
    }, [userInfo]);

    useEffect(() => {
        setValuesFilterGirl(defaultFiltersGirl(user_match, user_reference));
    }, [user_detail?.credits]);

    useEffect(() => {
        if (isLoggedIn) {
            getInfo();
        }
    }, [
        isLoggedIn,
        isOpenModalModalConfirmName,
        isOpenModalMailChange,
        isOpenModalMailConfirm,
        isOpenModalChangePassword,
        isOpenModalPhoneConfirm,
    ]);

    useEffect(() => {
        setValuesFilterMySelf(defaultFiltersMyselfFunc(user_detail, user_reference, user_hobby));
    }, [user_detail, user_reference, user_hobby]);

    useEffect(() => {
        const countryId = valuesFilterMySelf?.country?.id;
        if (debouncedCitySerch.length >= 3 && valuesFilterMySelf?.country) {
            getRequest(
                `/api/user/cityList?country_id=${countryId}&city_name=${debouncedCitySerch}`,
            ).then((res) => {
                if (res?.response?.length > 0) {
                    setCityOptions(
                        res?.response?.map((item) => ({
                            ...item,
                            value: item.city_name,
                            label: item.city_name,
                        })),
                    );
                }
            });
        } else {
            if (!valuesFilterMySelf.city) {
                setCityOptions([]);
            }
        }
    }, [debouncedCitySerch]);

    const getInfo = () => {
        dispatch(getUserInfo());
        dispatch(getMyProfile());
        dispatch(loaderOff());
    };

    const handleSave = () => {
        setVisibleSaveBtn(false);
        postRequest(
            'api/user/editProfile',
            configForEditProfile(valuesFilterMySelf, valuesFilterGirl),
        ).then(() => {
            getInfo();
        });
    };

    const onChangeOption = (name, value) => {
        if (!visibleSaveBtn) setVisibleSaveBtn(true);
        setValuesFilterMySelf({
            ...valuesFilterMySelf,
            [name]: value,
            city_name:
                name === 'city_name'
                    ? value
                    : name === 'country'
                      ? { label: 'Not specified', value: 'Not specified' }
                      : valuesFilterMySelf.city_name,
        });
    };

    const onInputChangeCity = (value) => {
        setCitySerch(value);
    };

    const onChangeOptionGirl = (name, value) => {
        if (!visibleSaveBtn) setVisibleSaveBtn(true);
        setValuesFilterGirl({ ...valuesFilterGirl, [name]: value });
    };

    const uploadImg = ({ file, fileName, index, mode, thenFunc }) => {
        uploadFile(file, fileName, user_detail?.external_id, AWS.MEN_IMAGES, index, thenFunc, mode);
    };

    const onChangeAvatar = (files) => {
        dispatch(loaderOn());
        uploadImg({
            file: files[0],
            fileName: 'avatar',
            index: 0,
            mode: 'avatar',
            thenFunc: getInfo,
        });
    };

    const handleAddPhoto = (files) => {
        dispatch(loaderOn());
        uploadImg({
            file: files[0],
            fileName: files[0]?.name?.split('.')[0],
            index: 0,
            mode: 'addPhotoProfile',
            thenFunc: updatePhotos,
        });
    };

    const updatePhotos = (item, del) => {
        postRequest(
            'api/user/editProfile',
            del || del === 0
                ? { user_content: [...(user_content ?? []).filter((_, i) => i !== del)] }
                : {
                      user_content: [...user_content, { content_type: 'image', link: item.link }],
                  },
        ).then(() => {
            getInfo();
            dispatch(loaderOff());
        });
    };

    const handleLogout = () => {
        dispatch(logOut());
    };

    const handleSimilar = (external_id) => {
        navigate(`/profile-woman/${external_id}`);
    };

    const handleDelAvatar = () => {
        dispatch(
            togleGlobalModal({
                isOpen: 'open',

                text: 'Delete your profile photo?',
                description: 'Press the “Delete photo” button to delete your profile photo',
                onClick: () =>
                    postRequest('/api/user/deleteUserAvatar', {}).then(() => {
                        getInfo();
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'close',
                            }),
                        );
                    }),
                btnText: 'Delete photo',
            }),
        );
    };

    return {
        filtersOptions: parserFilterOptions(filtersOptions),
        valuesFilterMySelf,
        onChangeOption,
        mmOptions,
        ddOptions: ddOptions(),
        yyyyOptions: yyyyOptions(),
        ageOptions: ageOptions(),
        valuesFilterGirl,
        onChangeOptionGirl,
        handleSave,
        onChangeAvatar,
        avatar,
        visibleSaveBtn,
        user_content,
        handleAddPhoto,
        updatePhotos,
        user_detail,
        onInputChangeCity,
        cityOptions,
        citySerch,

        isOpenModalMailChange,
        setIsOpenModalMailChange,
        isOpenModalMailConfirm,
        setIsOpenModalMailConfirm,
        isOpenModalChangePassword,
        setIsOpenModalChangePassword,
        isOpenModalPhoneConfirm,
        setIsOpenModalPhoneConfirm,
        isOpenModalModalConfirmName,
        setIsOpenModalModalConfirmName,
        handleLogout,
        userInfo,

        similarList,
        handleSimilar,
        handleDelAvatar,
        isShowGetVipBlock,
    };
};

export default useProfilePage;
