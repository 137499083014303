import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Box, TextNameonChat } from 'components/atomsKit/Elements';
import useMenuPage from './useMenuPage';
import { menuOptions } from 'data/config';
import useHandleActiveBlocks from 'hooks/useHandleActiveBlocks';
import { useHandleRouting } from 'hooks';
import { MIRROR } from 'data/mirrorConfig';
import classes from './MenuPage.module.scss';
import { useHandlePaymentDiscount } from '../../hooks/useHandlePaymentDiscount';

const MenuPage = () => {
    const { user_avatar, user_name } = useMenuPage();
    const navigate = useNavigate();
    const activeBlocks = useHandleActiveBlocks() as any;
    const { isActiveDiscount } = useHandlePaymentDiscount();

    const { launch } = useHandleRouting();

    return (
        <Box className={classes.wrap}>
            <Box flex className={classes.profileWrap}>
                {user_avatar ? (
                    <img alt="" className={classes.avatar} src={user_avatar} loading="lazy" />
                ) : (
                    <Box className={classes.avatarNone} flex align="center" justify="center">
                        <TextNameonChat
                            textAlign={'center'}
                            text={user_name?.slice(0, 2)}
                            className={classes.nameUser}
                            size={20}
                        />
                    </Box>
                )}
                <Box width100 marginLeft="10px" flex directionColumn justify={'center'}>
                    <Button
                        bgImg
                        onClick={() => {
                            navigate('/profile');
                        }}
                        text="My profile"
                        isWhite
                        iconSvg={'userMenu'}
                        className={classes.btnProfile}
                        classNameIcon={classes.btnIcon}
                    />
                    <Button
                        text={'Add credits'}
                        iconSvg={'copy_white'}
                        onClick={() => {
                            launch();
                        }}
                        className={isActiveDiscount ? classes.addBtn : ''}
                        discountText={isActiveDiscount ? 'Sale' : ''}
                    />
                </Box>
            </Box>
            <ul>
                {menuOptions.map((option) => (
                    <li key={option.name}>
                        {(!option.nameRules ||
                            (option.nameRules &&
                                (option.logicOperationRules
                                    ? activeBlocks[option.nameRules]
                                    : !activeBlocks[option.nameRules]))) && (
                            <Button
                                className={classes.btn}
                                isWhite
                                onClick={() => navigate(option.link)}
                                text={option.name}
                                iconSvg={option.icon}
                                bgImg
                                classNameIcon={classes.btnIcon}
                            />
                        )}
                    </li>
                ))}
            </ul>
            <Box flex align="center" justify="center" directionColumn className={classes.ligal}>
                <p>
                    © 2024
                    {MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].NAME.charAt(
                        0,
                    ).toUpperCase() +
                        MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].NAME.slice(
                            1,
                        ).toLowerCase()}
                    .com
                </p>
                <p>All rights reserved</p>
                <a
                    href={`mailto:${
                        MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR]
                            .SUPPORT_MAIL
                    }`}
                    className={classes.supportMail}
                >
                    {MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].SUPPORT_MAIL}
                </a>
            </Box>
        </Box>
    );
};

export default MenuPage;
