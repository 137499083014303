import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPaymentList } from '../redux/auth/selectors';

export enum CurrencyEnum {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
}

export enum PurchasePackageTypeEnum {
    MAIN = 'MAIN',
    SUPPORT = 'SUPPORT',
}

interface IPurchasePackageItem {
    id: number;
    credits: number;
    price: number;
    oldPrice: number;
    discount: number;
    siteID: number;
    category: number;
    title: string;
    status: number;
    first: number;
    vip: number;
    currency: CurrencyEnum;
    type: PurchasePackageTypeEnum;
}

export function useHandlePaymentDiscount() {
    const [isActiveDiscount, setIsActiveDiscount] = useState(false);
    const paymentList = useSelector(selectUserPaymentList);

    useEffect(() => {
        const isDiscount =
            !!paymentList?.length &&
            paymentList?.some(
                (item: IPurchasePackageItem) => item?.type === PurchasePackageTypeEnum.SUPPORT,
            );
        setIsActiveDiscount(isDiscount);
    }, [paymentList]);

    return { isActiveDiscount };
}
